import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/SEO';
import Layout from '../layouts/index';
import { MDXRenderer } from "gatsby-plugin-mdx"

const Project = ({ data }) => {
  const { seoTitle } = data.mdx.frontmatter;
  const { title } = data.mdx.frontmatter;
  const { seoDescription } = data.mdx.frontmatter;
  const { body } = data.mdx.body;
  return (

    <Layout bodyClass="page-project">
      <SEO 
      title= {seoTitle}
      description= {seoDescription}
      />
      <div className="strip strip-gray strip-diagonal">
        <div className="container pt-4 pt-md-10">
              <div className="project project-single">
                <h1 className="title">{seoTitle}{title}</h1>
                <MDXRenderer>{data.mdx.body}</MDXRenderer>
              </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query($id: String!) {
    mdx(id: { eq: $id }) {
      frontmatter {
        title
        seoTitle
        seoDescription
        path
      }
      body
    }
  }
`;

export default Project;